import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><strong parentName="p">{`The preorder is closed for this round! We are going to order the jerseys and
ship it to everyone as soon as we get them. You will probably have it in your
mailbox between the 10th-15th of October Stay tuned for when we are going to
print the next batch.`}</strong></p>
    <Image src="news/frontandback.jpg" mdxType="Image" />
    <p>{`Trailguide is going to print jerseys! The shirts with the unique Trailguide
design are made for biking, are breathable and dry quickly.`}</p>
    <br />
    <br />
    <p>{`We are going to take orders for the next two weeks, then print and ship your
jerseys.`}<br /><br /></p>
    <p><strong parentName="p">{`
1 ) Vipps 300,-kr to 571734`}<br />{`
2 ) Write your full address in the Vipps message`}<br />{`
3 ) Write the size of the jersey in the Vipps message`}<br />
      </strong></p>
    <br />
    <br />
    <p>{`The sizing is a bit on the larger side, so if you are not sure, take the
smaller size. Unfortunately we can only send the jerseys to Norway for now.`}</p>
    <br />
    <br />
    <p align="center">
  <img width="300" src="https://www.vipps.no/documents/61/vipps-rgb-orange-neg.svg" />
    </p>
    <p className="text-center text-4xl mt-6 text-orange-700 font-bold">571734</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      